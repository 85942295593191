import React from "react"
import { graphql } from "gatsby"
import App from "../components/app"
import HeaderImg from "../../content/assets/header.png"
import Block1Img from "../../content/assets/block_1.png"
import Block2Img from "../../content/assets/block_2.png"
import Block3Img from "../../content/assets/block_3.jpg"
import Block4Img from "../../content/assets/block_4.png"

export default function Index() {
  return (
    <App title="Home">
      <div className="max-w-full mx-auto">
        <img src={HeaderImg} className="w-full" alt="Header Img" />
      </div>
      <section className="bg-white py-8">
        <div className="container mx-auto">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Why Choose Us?
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 p-6">
              <div className="flex flex-wrap -mx-4">
                <div className="md:w-1/2 h-auto px-2">
                  <div className="mb-4">
                    <img className="rounded shadow-md" src={Block1Img} alt="" />
                  </div>
                  <div>
                    <img className="rounded shadow-md" src={Block3Img} alt="" />
                  </div>
                </div>
                <div className="md:w-1/2 h-auto px-2">
                  <div className="mb-4">
                    <img className="rounded shadow-md" src={Block2Img} alt="" />
                  </div>
                  <div>
                    <img className="rounded shadow-md" src={Block4Img} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-5/6 sm:w-1/2 p-6 flex items-center">
              <div>
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  The best place to find your stay!
                </h3>
                <p className="text-gray-600 mb-8">
                  Organizing the perfect getaway has never been easier than now,
                  with our GoGo platform. You can inspire traveling ideas and
                  explore all our unique listings for your preferred destination
                  and requirements. We offer high-quality listings for all kinds
                  of budgets, whether it’s a short or long stay we got you
                  covered!.
                </p>
                <div className="grid grid-cols-2 gap-4">
                  <div className="inline-flex">
                    <div className="rounded-full h-8 w-8 flex items-center justify-center bg-blue-100">
                      <span className="text-green-500">1 </span>
                    </div>
                    <span className="inline-flex pl-3">
                      {" "}
                      Safety is our highest priority.{" "}
                    </span>
                  </div>
                  <div className="inline-flex">
                    <div className="rounded-full h-8 w-8 flex items-center justify-center bg-blue-100">
                      <span className="text-green-500">2 </span>
                    </div>
                    <span className="inline-flex pl-3">
                      {" "}
                      We are big on comfort.{" "}
                    </span>
                  </div>
                </div>
                <div className="inline-flex mt-4">
                  <div className="rounded-full h-8 w-8 flex items-center justify-center bg-blue-100">
                    <span className="text-green-500"> 3 </span>
                  </div>
                  <span className="inline-flex pl-3">
                    {" "}
                    Hassle free Check-In &amp; Check-Out.{" "}
                  </span>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </App>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
